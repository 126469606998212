import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getLead } from "../services/api";
import { deleteLead, updateLead } from '../services/api';
import LeadNotes from "./LeadNotes";
import TasksList from "./TasksList";
import LeadStatusFlow from "./LeadStatusFlow"
import DocumentUpload from "./DocumentUpload";
import DocumentList from "./DocumentList";
import ObjectHistory from "./ObjectHistory";
import { useNavigate } from "react-router-dom";
import { formatLeadType } from '../utils/displayFormatters';
import { formatLeadSource} from '../utils/displayFormatters';

const LeadDetail = ({ authToken }) => {
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

    const fetchLead = async () => {
      if (!id) {
        setError("Invalid lead ID");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const data = await getLead(authToken, id);
        setLead(data);
        setError(null);
      } catch (err) {
        console.error("Error fetching lead:", err);
        setError("Failed to fetch lead details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => { fetchLead(); }, [authToken, id]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "new":
        return "bg-sky-100 text-sky-700";
      case "contacted":
        return "bg-amber-100 text-amber-700";
      case "qualified":
        return "bg-green-100 text-green-800";
      case "lost":
        return "bg-red-100 text-red-70";
      case "converted":
        return "bg-emerald-100 text-emerald-700";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };
  // Add state for status change
  const [updatingStatus, setUpdatingStatus] = useState(false);

  // Add handler for status change
  const handleStatusChange = async (newStatus) => {
    try {
      setUpdatingStatus(true);
      await updateLead(authToken, id, { status: newStatus });
      await fetchLead();
    } catch (err) {
      console.error("Error updating status:", err);
    } finally {
      setUpdatingStatus(false);
    }
  };

  if (loading)
    return <div className="text-center py-8">Loading lead details...</div>;
  if (error)
    return <div className="text-center py-8 text-red-600">{error}</div>;
  if (!lead) return <div className="text-center py-8">No lead found.</div>;

  return (
    <div className="bg-white p-4 overflow-x-auto">
      <div className="mb-8 sm:flex sm:items-center sm:justify-between">
        <h2 className="text-gray-900 text-xl font-semibold mb-4">
          Lead Details
        </h2>
        <div className="mt-4 sm:mt-0">
          <Link
            to="/leads"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-3"
          >
            {" "}
            Back to Leads{" "}
          </Link>
          <Link
            to={`/leads/${lead.id}/edit`}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-btn-primary hover:bg-btn-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-3"
          >
            Edit Lead
          </Link>
          <button
            onClick={async () => {
              if (
                window.confirm("Are you sure you want to delete this lead?")
              ) {
                try {
                  await deleteLead(authToken, lead.id);
                  navigate("/leads");
                } catch (error) {
                  console.error("Error deleting lead:", error);
                }
              }
            }}
            className="inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-3"
          >
            Delete Lead
          </button>
        </div>
      </div>

      <div className="bg-white p-8 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <h2 className="text-xl font-bold text-gray-900">
            {lead.first_name} {lead.last_name}
          </h2>
          <LeadStatusFlow
            currentStatus={lead.status}
            onStatusChange={handleStatusChange}
          />
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div>
              <dt className="text-gray-500 text-sm">Email</dt>
              <dd className="mt-1 text-gray-900">{lead.email}</dd>
            </div>
            <div>
              <dt className="text-gray-500 text-sm">Phone</dt>
              <dd className="mt-1 text-gray-900">{lead.phone}</dd>
            </div>
            <div>
              <dt className="text-gray-500 text-sm">Type</dt>
              <dd className="mt-1 text-gray-900">{formatLeadType(lead.type) || "N/A"}</dd>
            </div>
            <div>
              <dt className="text-gray-500 text-sm">Source</dt>
              <dd className="mt-1 text-gray-900">{formatLeadSource(lead.source) || "N/A"}</dd>
            </div>
            <div>
              <dt className="text-gray-500 text-sm">Location</dt>
              <dd className="mt-1 text-gray-900">
                {lead.location?.name || "N/A"}
              </dd>
            </div>
            <div>
              <dt className="text-gray-500 text-sm">Insurance Provider</dt>
              <dd className="mt-1 text-gray-900">
                {lead.insurance_provider?.name || "N/A"}
              </dd>
            </div>
            <div>
              <dt className="text-gray-500 text-sm">Created At</dt>
              <dd className="mt-1 text-gray-900">
                {new Date(lead.created_at).toLocaleDateString()}
              </dd>
            </div>
          </dl>
        </div>

        {lead.address && (
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <h3 className="text-md font-medium text-gray-900 mb-3">Address</h3>
            <address className="text-sm text-gray-600 not-italic">
              <p>{lead.address.street}</p>
              <p>
                {lead.address.city}, {lead.address.state}{" "}
                {lead.address.postal_code}
              </p>
              <p>{lead.address.country}</p>
            </address>
          </div>
        )}
      </div>

      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg w-full">
        <LeadNotes authToken={authToken} id={id} />
      </div>

      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg w-full">
        <div className="border-t border-gray-200 w-full">
          <TasksList authToken={authToken} leadId={id} />
        </div>
      </div>

      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg w-full">
        <div className="border-t border-gray-200 w-full">
          <ObjectHistory
            authToken={authToken}
            objectType="lead"
            objectId={id}
          />
        </div>
      </div>
    </div>
  );
};

export default LeadDetail;
