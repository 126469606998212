// utils/displayFormatters.js

export const formatLeadSource = (source) => {
  const sourceMapping = {
    'contact_form': 'Contact Form',
    'referral': 'Referral',
    'website': 'Website',
    'phonecall': 'Phone',
    'webforms': 'Webforms',
    'online_booking': 'Online Booking'
  };

  return sourceMapping[source] || source;
};

export const formatLeadType = (type) => {
  if (!type) return '';
  
  // First replace underscores with spaces
  let formatted = type.replace(/_/g, ' ');
  
  // Handle special case for 'chatbot' -> 'Chat Bot'
  if (formatted === 'chatbot') {
    return 'Chat Bot';
  }

  if (formatted === 'phonecall') {
    return 'Phone';
  }

  // Split by spaces and capitalize each word
  return formatted
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

// Add status mapping
export const LEAD_STATUSES = [
  { key: "new", label: "New Lead", color: "bg-sky-700" },
  { key: "contacted", label: "Contacted", color: "bg-yellow-500" },
  { key: "scheduled", label: "Scheduled", color: "bg-green-500" },
  { key: "qualified", label: "New Patient", color: "bg-green-500" },
  { key: "existing", label: "Existing Patient", color: "bg-purple-500" },
  { key: "noshow", label: "No Show", color: "bg-red-100" },
  { key: "lost", label: "Not Interested", color: "bg-red-100" },
];

export const LEAD_TYPES = [
  { key: "contact_form", label: "Contact Form"},
  { key: "phonecall", label: "Phone"},
  { key: "chatbot", label: "Chat Bot"},
];

export const LEAD_SOURCES = [
  { key: "google", label: "Google" },
  { key: "facebook", label: "FaceBook" },
  { key: "instagram", label: "Instagram" },
  { key: "youtube", label: "YouTube" },
  { key: "other", label: "Other Social Media" },
  { key: "preferral", label: "Patient Referral" },
  { key: "sreferral", label: "Staff Referral" },
  { key: "dreferral", label: "Doctor Referral" },
  { key: "sgnage", label: "Sginage/Walk by" },
  { key: "flyer", label: "Flyer/Postcard" },
  { key: "Seminar", label: "Seminar" },
  { key: "tradeshow", label: "Tradeshow/Community Event" }
];

// Helper function to get status label
export const formatLeadStatus = (status) => {
  const statusObj = LEAD_STATUSES.find(s => s.key === status?.toLowerCase());
  return statusObj?.label || status;
};

// Helper function to get status color
export const getLeadStatusColor = (status) => {
  const statusObj = LEAD_STATUSES.find(s => s.key === status?.toLowerCase());
  return statusObj?.color || "bg-gray-500";
};